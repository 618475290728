import { useIsAppReady } from '@finn/platform-modules';
import {
  FilterKey,
  FilterValuesObject,
  useFilterValues,
  useReplaceFilterValues,
} from '@finn/ua-vehicle';
import { memo, useEffect } from 'react';

export const RestoreDefaultFilters = memo(function RestoreFiltersOnMount({
  defaultFilterValues,
}: {
  defaultFilterValues: FilterValuesObject;
}) {
  const isAppReady = useIsAppReady();
  const replaceFilterValues = useReplaceFilterValues();

  const filtersCount = useFilterValues(
    (filters) => Object.keys(filters).length
  );

  useEffect(() => {
    const nextFilterValues: FilterValuesObject = {
      ...defaultFilterValues,
      // TODO: Base this on implicit filters
      [FilterKey.HAS_DEALS]: undefined,
      [FilterKey.PRODUCT_GROUP]: undefined,
      [FilterKey.HAS_RETENTION_DEALS]: undefined,
      [FilterKey.VIEW]: undefined,
    };

    if (!isAppReady || filtersCount !== 0) {
      return;
    }

    replaceFilterValues(nextFilterValues);
  }, [isAppReady, filtersCount, defaultFilterValues]);

  return null;
});
