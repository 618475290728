'use client';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useRef, useState } from 'react';

export function useDebouncedValue<ValueType>(
  value: ValueType,
  delay: number
): ValueType {
  const [debouncedValue, setDebouncedValue] = useState(value);
  const updateTimeoutRef = useRef<number | null>(null);

  const clearUpdateTimeout = useCallback(() => {
    if (updateTimeoutRef.current !== null) {
      clearTimeout(updateTimeoutRef.current);
      updateTimeoutRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (isEqual(value, debouncedValue)) {
      return;
    }

    clearUpdateTimeout();

    updateTimeoutRef.current = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearUpdateTimeout();
    };
  }, [value, delay, clearUpdateTimeout, debouncedValue]);

  return debouncedValue;
}
