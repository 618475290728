import { memo, ReactNode, useMemo } from 'react';

import { FilterValuesObject, QueryFilterMapper } from '../../core';
import { FilterProvider } from '../data/lib';

type QueryFilterProviderProps = {
  basePathname: string;
  defaultFilterValues?: FilterValuesObject;
  children?: ReactNode;
};

export const QueryFilterProvider = memo(function QueryFilterProvider({
  basePathname,
  children,
}: QueryFilterProviderProps) {
  const filterMapper = useMemo(
    () => new QueryFilterMapper(basePathname),
    [basePathname]
  );

  return (
    <FilterProvider filterMapper={filterMapper}>{children}</FilterProvider>
  );
});
